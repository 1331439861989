import { checkConnection } from "./check_connection"
import { getAudioElement } from "./utils/getAudioElement";

export function connectionMonitor() {
    // descomentar as linhas abaixo para trocar de Wi-Fi para 5G em Android
    setInterval(() => {
        const playing = window.isPlaying?.();
        if (!playing) {
            return;
        }
        checkConnection(() => {
            document.dispatchEvent(new CustomEvent("statusChanged", { detail: "online" }));
        }, () => {
            document.dispatchEvent(new CustomEvent("statusChanged", { detail: "offline" }));
        });
    }, 5000);

    function onAudioInterrupt() {
        checkConnection(() => {
            // online
            console.log('[connectionMonitor] está online');
            if (!window.player?.isPlaying()) {
                window.player?.play();
            }
            window.isOnline = true;
            window.isOfflinePlaying = false;
        }, () => {
            // offline
            console.log('[connectionMonitor] está offline');
            window.isOnline = false;
            window.isOfflinePlaying = false;
        });
    }

    function onPlaying() {
        console.log('[connectionMonitor] onPlaying');

        const audio = getAudioElement();

        audio.removeEventListener("stalled", onAudioInterrupt);
        audio.addEventListener("stalled", onAudioInterrupt);

        audio.removeEventListener("ended", onAudioInterrupt);
        audio.addEventListener("ended", onAudioInterrupt);
    }

    function onPlayerReady() {
        console.log('[connectionMonitor] onPlayerReady');
        window.player?.event('audioLoaded', function () {
            console.log('[connectionMonitor] onPlayerReady -> audioLoaded');
           
            const audio = getAudioElement();

            audio.removeEventListener("playing", onPlaying);
            audio.addEventListener("playing", onPlaying);
        });
    }
    document.removeEventListener("playerReady", onPlayerReady);
    document.addEventListener("playerReady", onPlayerReady, false);
}