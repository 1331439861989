const noop = () => { };

// TODO: utilizar conexão WebSocket em vez de HTTP polling
export function checkConnection(onlineCallback = noop, offlineCallback = noop, timeoutMs = 5000) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
        controller.abort("TIMEOUT");
    }, timeoutMs);
    fetch('https://www.google.com/robots.txt', {
        signal: controller.signal,
        method: 'HEAD',
        redirect: 'follow',
        mode: 'no-cors',
    })
        .then(onlineCallback)
        .catch(offlineCallback)
        .finally(() => {
            clearTimeout(timeoutId);
        });
}
