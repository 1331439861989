import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { disableOfflineModeListeners } from "../../offline_mode";
import { getAudioElement } from "../../utils/getAudioElement";
import "./style.scss";

export function OfflineModeIndicator() {
  const defaultStatus = "Instalando<br>Modo Offline";
  const [status, setStatus] = useState(defaultStatus);
  const [testMode, setTestMode] = useState(process.env.NODE_ENV === "development");

  useEffect(() => {
    (window as any).setOfflineModeStatus = setStatus;

    function onUpdateOfflineModeIndicator() {
      const { loaded, color, text } = (window as any).OfflineModeIndicator;
      const bolinha: HTMLElement | null = document.querySelector(".bolinha");
      if (loaded && bolinha) {
        bolinha.style.color = color;
        setStatus(text);
      }
    }
    document.addEventListener(
      "updateOfflineModeIndicator",
      onUpdateOfflineModeIndicator
    );

    const defaultIndicator = {
      loaded: true,
      color: "red",
      text: defaultStatus,
    };
    (window as any).OfflineModeIndicator =
      (window as any).OfflineModeIndicator || defaultIndicator;

    (window as any).OfflineModeIndicator.loaded = true;

    // atualiza a cor da bolinha
    document.dispatchEvent(new CustomEvent("updateOfflineModeIndicator"));

    return () => {
      document.removeEventListener(
        "updateOfflineModeIndicator",
        onUpdateOfflineModeIndicator
      );
    };
  }, []);

  const clicks = useRef(0);
  const testarModoOffline: MouseEventHandler<HTMLDivElement> =
    function _testarModoOffline(event) {
      console.log('_testarModoOffline');
      event.preventDefault();
      event.stopPropagation();
      if (clicks.current === 4) {
        (window as any).forceOfflineMode = true;
        (window as any).swal?.({
          title: "Modo Teste",
          text: "Você ativou o modo de teste do modo offline!",
        });
        document.dispatchEvent(
          new CustomEvent("statusChanged", { detail: "offline" })
        );
        setTimeout(() => {
          disableOfflineModeListeners();
        });
        setTestMode(true);
      } else {
        clicks.current++;
      }
    };

  return (
    <div className="offline-indicator" onClick={testMode ? undefined : testarModoOffline}>
      <span className="bolinha">⬤</span>
      <span className="texto" dangerouslySetInnerHTML={{ __html: status }} />
      {testMode && <div className="buttons">
        <button type="button" onClick={() => {
          (window as any).playNextOfflineAudio?.();
        }}>
          Próxima música offline
        </button>
        <button type="button" onClick={() => {
          const audio = getAudioElement();
          if (audio.duration && audio.duration !== Infinity) {
            audio.currentTime = audio.duration - 5;
          }
        }}>
          Avançar para o final da música offline
        </button>
        <button type="button" onClick={() => document.location.reload()}>
          {process.env.NODE_ENV === "development"
            ? "Atualizar página"
            : "Sair do modo de teste"
          }
        </button>
      </div>}
    </div>
  );
}
