import { useMemo } from "react";
import { TrackInfoProps } from "./types";
import { format } from "date-fns";

import "./style.scss";

export function TrackInfo({ title, artwork, time }: TrackInfoProps) {
  const dateFormatted = useMemo(() => {
    if (!time) {
      return "";
    }
    const timeObj = new Date(time);
    return format(timeObj, "dd/MM/yy");
  }, [time]);

  const timeFormatted = useMemo(() => {
    if (!time) {
      return "";
    }
    const timeObj = new Date(time);
    return format(timeObj, "HH:mm:ss");
  }, [time]);

  return (
    <div className="track-info-component">
      {artwork && <img src={artwork} alt="" />}
      <span className="title">{title}</span>
      {time && (
        <span className="time">
          {dateFormatted}
          <br />
          {timeFormatted}
        </span>
      )}
    </div>
  );
}
