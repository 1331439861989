import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from "./components/Container";
import { connectionMonitor } from "./connection_monitor";
import { afterRegister } from "./offline_mode";
import { loginIndex } from "./pages/Login";
import { LoginOrPlayer } from "./pages/LoginOrPlayer";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from './reportWebVitals';

import "./global.scss";
import "./popup_canopy.scss";

/*
 * Importing directly from the element defines the element class
 * and registers it with the global element registry with the tag
 * name 'ken-burns-carousel'.
 */
import "ken-burns-carousel";

function blockRightClickAndInspect() {
  document.addEventListener("contextmenu", function (event) {
    event.preventDefault();
  });
  document.addEventListener("keydown", function (event) {
    if (
      event.keyCode === 123 ||
      (event.ctrlKey && event.shiftKey && event.keyCode === 73)
    ) {
      event.preventDefault();
    }
  });
}

if (process.env.NODE_ENV !== "development") {
  blockRightClickAndInspect();
}

(window as any).afterRegister = afterRegister;

(window as any).isPlaying = function isPlaying() {
  if ((window as any).isOfflinePlaying) {
    console.log("window.isPlaying() utilizou window.isOfflinePlaying");
    return true;
  }
  return Boolean(document.querySelector("#play_pause .fa-pause"));
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Toaster />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Container />}>
          {Object.keys(loginIndex).map((grupo) => (
            <Route key={grupo} path={grupo} element={loginIndex[grupo]} />
          ))}
          <Route path=":idRadio" element={<LoginOrPlayer />} />
          <Route path=":idRadio/:cnpj" element={<LoginOrPlayer />} />
          <Route path=":idRadio/:cnpj/:senha" element={<LoginOrPlayer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

connectionMonitor();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ afterRegister });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

console.warn("[index.tsx] Versão 3");
