import debounce from "just-debounce";
import swal from "sweetalert";
import { env } from "../../../env";
import {
  disableOfflineModeListeners,
  enableOfflineModeListeners,
} from "../../../offline_mode";
import { getAudioElement } from "../../../utils/getAudioElement";
import { getVolume } from "../../../utils/getVolume";
import { playAnotherAudio } from "../../../utils/playAnotherAudio";

async function streamToBlob(stream) {
  const reader = stream.getReader();
  const chunks = [];
  let done, value;

  while (!done) {
    ({ done, value } = await reader.read());
    if (!done) {
      chunks.push(value);
    }
  }

  return new Blob(chunks);
}

function getFormData($form) {
  var unindexed_array = $form.serializeArray();
  var indexed_array = {};
  window.jQuery.map(unindexed_array, function (n) {
    indexed_array[n["name"]] = n["value"];
  });
  return indexed_array;
}

export function loadRadio(radio) {
  var Hls = window.Hls;
  if (!Hls) {
    console.error("Falha ao carregar a biblioteca Hls");
    return;
  }
  var $ = window.jQuery;
  if (!$) {
    console.error("Falha ao carregar a biblioteca jQuery");
    return;
  }

  document.body.classList.add("stopped");

  window.setTimeout(() => {
    console.log("loadRadio: ", radio);

    function isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }

    if (!isTouchDevice()) {
      $('[data-toggle="tooltip"]').tooltip({
        delay: { show: 1, hide: 1 },
        trigger: "hover",
      });
    }

    $("#audios_modal").on("hidden.bs.modal", function () {
      $("#audios_modal").removeClass("visible-while-playing");
    });

    if (isHls()) {
      console.log("loading HLS radio with m3u8:", radio.url);
      var hlsPlayer = document.getElementById("audio");
      hlsPlayer.onplay = onStart;
      hlsPlayer.onpause = onStop;
      if (Hls.isSupported()) {
        // https://github.com/video-dev/hls.js/blob/master/docs/API.md
        var config = {
          // debug: true,
          startLevel: undefined,
          levelLoadingTimeOut: 10000,
          levelLoadingMaxRetry: 99,
          levelLoadingRetryDelay: 500,
          levelLoadingMaxRetryTimeout: 10000,
          fragLoadingTimeOut: 10000,
          fragLoadingMaxRetry: 99,
          fragLoadingRetryDelay: 500,
          fragLoadingMaxRetryTimeout: 10000,
          startFragPrefetch: false,
        };
        var hls = new Hls(config);
        hls.loadSource(radio.url);
        hls.attachMedia(hlsPlayer);
        hls.on(Hls.Events.ERROR, function (event, data) {
          console.log("Hls.Events.ERROR", data);
        });
        hls.on(Hls.Events.FRAG_LOADED, function (event, data) {
          var dados = data.frag.tagList[0][2];
          if (dados) {
            dados = JSON.parse(dados);
            var artwork = dados.imagem ? "https:" + dados.imagem : "";
            loadMetadata(dados.titulo, artwork);
          }
        });
      } else {
        alert("Este navegador não suporta a tecnologia HLS");
      }
    } else {
      // Remove HLS player
      document.getElementById("hls-container")?.remove();

      window.radioStatus = "";
      if (radio.url.match(/(s[0-9a-f]{9})/g)) {
        var player = $(".radioplayer").radiocoPlayer();
        window.player = player;
        document.dispatchEvent(new CustomEvent("playerReady"));
        player.event("songChanged", function (infos) {
          if (window.forceOfflineMode) {
            return;
          }
          let status = infos?.message?.status ?? "offline";
          if (window.radioStatus !== status) {
            if (window.radioStatus !== "" || status === "offline") {
              document.dispatchEvent(
                new CustomEvent("statusChanged", { detail: status })
              );
            }
            window.radioStatus = status;
          } else if (status === "online") {
            loadMetadata(infos.trackTitle, infos.trackArtwork);
          }
        });
      } else {
        console.error(
          "O URL da rádio não possui um id no formato s[0-9a-f]{9} (exemplo: sf38694d4a)"
        );
      }
    }

    var contentPedir = $("#content_pedir");
    var scriptUrl = contentPedir.html();
    var script = document.createElement("script");
    contentPedir.append(script);
    script.type = "text/javascript";
    script.addEventListener("load", function onScriptLoad() {
      console.log("pedir música carregado com sucesso :)");
      $('iframe[src^="//embed.radio.co/request/"]').appendTo(contentPedir);

      if (
        String(
          document.querySelector("#content_pedir").childNodes[0].nodeValue
        ).includes("radio.co")
      ) {
        document.querySelector("#content_pedir").childNodes[0].nodeValue = "";
      }
    });
    script.src = scriptUrl;

    $("#close_pop_ask").click(function () {
      $("#pedir_musica").fadeOut();
    });

    $(".botao:has(#play_pause)").click(function (e) {
      e.preventDefault();
      $(".tooltip").tooltip("hide");
      const playing = window.isPlaying();
      $("#play_pause svg").remove();
      if (playing) {
        stop();
      } else {
        play();
      }
      return false;
    });

    // libera o botão "Falar" ao abrir a janela modal
    $("#tts_modal").on("shown.bs.modal", function () {
      $("#tts_modal fieldset").removeProp("disabled");
    });

    // limpa o formulário quando a janela modal for fechada
    $("#tts_modal").on("hide.bs.modal", function () {
      $("#tts_modal fieldset").removeProp("disabled");
      $("#tts_modal form")[0].reset();
    });

    let playbackRate = 1.0;
    document.addEventListener("setPlaybackRate", (event) => {
      playbackRate = event.detail.playbackRate;
      console.log("playbackRate=", playbackRate);
    });

    function ttsRadioIndoor(text, voice, onPlay, onEnded, beep) {
      var url = "https://radioindoor.com.br/tts/";
      var formParams = encodeURIComponent(
        JSON.stringify({
          fabriccaradio: "1",
          texto: text,
          locutor: voice,
        })
      );
      var host = encodeURIComponent(env.api.host);
      fetch(
        `${env.api.host}/proxy.php?method=POST&host=${host}&url=${url}&form_params=${formParams}`
      )
        .then((response) => response.text())
        .then((result) => {
          console.log("proxy.php result:", result);
          if (beep) {
            playAnotherAudio(document.querySelector("#tts_audio"), {
              src: "/beep.mp3",
              isHls: isHls(),
              playMainAudioOnEnd: false,
              onPlay,
              onEnded: () => {
                playAnotherAudio(document.querySelector("#tts_audio"), {
                  src: result,
                  isHls: isHls(),
                  playbackRate,
                  onEnded,
                });
              },
            });
          } else {
            playAnotherAudio(document.querySelector("#tts_audio"), {
              src: result,
              isHls: isHls(),
              playbackRate,
              onPlay,
              onEnded,
            });
          }
        })
        .catch((error) => console.log("proxy.php error:", error));
    }

    function ttsElevenLabs(text, voice, onPlay, onEnded) {
      var token = "b3vutd5cvgg5mc1tdnnse8c2kytwapsl";
      fetch(`https://tts.fabriccamultimidia.com.br/?token=${token}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text, voice }),
      })
        .then((response) => {
          streamToBlob(response.body).then((blob) => {
            playAnotherAudio(document.querySelector("#tts_audio"), {
              src: URL.createObjectURL(blob),
              playbackRate,
              onPlay,
              onEnded,
            });
          });
        })
        .catch((error) => console.log("tts error:", error));
    }

    $("#tts_modal form").submit(function (event) {
      var text = $("#tts_modal form").find(":input[name='text']").val().trim();
      var voice = $("#tts_modal form").find(":input[name='voice']").val();
      var beep = $("#tts_modal form")
        .find(":input[name='beep']")
        .is(":checked");

      // impede o envio caso a mensagem esteja em branco
      if (text === "") {
        swal({
          title: "Aviso",
          text: "Digite o texto a ser falado.",
          icon: "warning",
        });
        event.preventDefault();
        return false;
      }

      // bloqueia o botão de submit por alguns segundos para evitar envios repetidos
      $("#tts_modal fieldset").prop("disabled", "disabled");
      $("#processando").html("Aguarde, processando...");

      const onPlay = () => {
        $("#processando").html("Reproduzindo...");
      };
      const onEnded = () => {
        $("#tts_modal fieldset").removeProp("disabled");
      };
      if (radio.tts === 1) {
        ttsRadioIndoor(text, voice, onPlay, onEnded, beep);
      } else if (radio.tts === 2) {
        ttsElevenLabs(text, voice, onPlay, onEnded);
      } else {
        console.warn("Provedor de TTS não identificado.");
      }

      event.preventDefault();
      return false;
    });

    $("#email form").submit(function () {
      $("#email form input[type='submit']")
        .val("Enviando, por favor aguarde...")
        .prop("disabled", true);

      var headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("X-Requested-With", "XMLHttpRequest");

      var formData = getFormData($("#email form"));

      fetch(`${env.api.host}/api/support`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          radio_id: radio.id,
          name: formData.nome,
          department: formData.departamento,
          subject: formData.assunto,
          email: formData.email,
          phone_number: formData.telefone,
          message: formData.mensagem,
        }),
        redirect: "follow",
      })
        .then(() => {
          swal({
            title: "Sucesso!",
            text: "Mensagem enviada.",
            icon: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          $("#email form")[0].reset();
          $("#email form input[type='submit']")
            .val("Enviar Mensagem")
            .prop("disabled", false);
        });

      return false;
    });

    const isIos = (function () {
      const iosQuirkPresent = function () {
        const audio = new Audio();
        audio.volume = 0.5;
        return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
      };

      const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isAppleDevice = navigator.userAgent.includes("Macintosh");
      const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

      return isIos || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
    })();

    // http://meuplay/teste?autoplay=1
    const autoplay = Boolean(window.location.search.match(/autoplay=1/));
    // alert(
    //   `autoplay=${autoplay} | window.isMobile=${window.isMobile} | window.isPwa=${window.isPwa} | isIos=${isIos}`
    // );
    if (autoplay && !isIos && (!window.isMobile || window.isPwa)) {
      play();
    }
  });

  function beforePlay(audio) {
    $("#overflow_player").addClass("loading").show();
    audio.removeEventListener("playing", onStart);
    audio.addEventListener("playing", onStart);
  }

  function playOffline() {
    enableOfflineModeListeners();
    document.dispatchEvent(
      new CustomEvent("statusChanged", { detail: "offline" })
    );
    const audio = getAudioElement();
    beforePlay(audio);
    if (window.forceOfflineMode) {
      // mantém os listeners desligados
      setTimeout(() => {
        disableOfflineModeListeners();
      });
    }
  }

  function onAutoPlayError(e) {
    console.error("[onAutoPlayError]", e);
    $("#overflow_player").removeClass("loading").hide();
    onStop();
  }
  window.onAutoPlayError = onAutoPlayError;

  function play() {
    try {
      /**
       * @type {HTMLAudioElement | null}
       */
      let audio = null;
      if (isHls()) {
        // Radio Indoor (somente online)
        audio = document.getElementById("audio");
      } else if (window.radioStatus === "offline" || !window.isOnline) {
        // Radio.co offline
        playOffline();
      } else {
        // Radio.co online
        audio = getAudioElement();
        audio.crossOrigin = "anonymous";
        audio.load();
      }
      if (audio) {
        beforePlay(audio);
        const playResult = audio.play();
        if (playResult instanceof Promise) {
          playResult.then(
            () => {},
            (e) => {
              onAutoPlayError(e);
            }
          );
        }
      }
    } catch (e) {
      onAutoPlayError(e);
    }
  }
  window.play = play;

  function onStart() {
    const initialVolume = getVolume();
    console.log("🔊 Volume inicial:", initialVolume);
    if (window.player) {
      window.player.volume?.(initialVolume);
    } else {
      const audio = getAudioElement();
      audio.volume = initialVolume;
    }
    $("#overflow_player").removeClass("loading").hide();
    $("#play_pause").html(
      '<div class="fa fa-pause" style="vertical-align: middle;" ></div>'
    );
    document.body.classList.remove("stopped");
  }

  function stop() {
    let audio = null;
    if (isHls()) {
      audio = document.getElementById("audio");
    } else {
      audio = getAudioElement();
    }
    if (audio) {
      audio.pause();
    }
    onStop();
  }

  function onStop() {
    $("#play_pause").html(
      '<div class="fa fa-play-circle" style="vertical-align: middle;" ></div>'
    );
    document.body.classList.add("stopped");
    window.isOfflinePlaying = false;
  }

  function isHls() {
    return radio.type_server === "hls";
  }

  function setCurrentTrack(title, artwork, offline) {
    let song = "";
    let artist = "";
    if (title != null && title !== "") {
      let song_parts = title.split(" - ");
      artist = song_parts[0] ?? "";
      song = song_parts[1] ?? "";
    }
    $("#song").html(song);
    $("#artist").html(artist);

    if (offline) {
      window.radioStatus = "offline";
    }
    if (window.radioStatus === "offline") {
      return;
    }
    let $img;
    if (!artwork) {
      artwork = "/images/logo-white-border.png";
    }
    if (isHls()) {
      // HLS (Radio Indoor / Kako)
      $img = $("#artwork");
    } else {
      // Non-HLS (Radio.co)
      $img = $(".radioco-image");
    }
    $img.attr(
      "src",
      artwork.replace("100x100", "600x600").replace(".100.", ".600.")
    );
    $("#bg_blur").css("background-image", "url('" + $img.attr("src") + "')");
  }
  window.setCurrentTrack = setCurrentTrack;

  window.refreshTrackInfo = debounce(
    function refreshTrackInfo() {
      if (window.radioStatus !== "online") {
        return;
      }
      if (radio?.type_server !== "hls" && radio?.streaming_id) {
        // A Seguir
        const nextUrl = `https://public.radio.co/stations/${radio.streaming_id}/next`;
        fetch(nextUrl)
          .then((response) => response.json())
          .then((result) => {
            const title = result?.next_track?.title;
            const artwork = result?.next_track?.artwork_url;
            window?.setNextTrack({
              title,
              artwork,
            });
          })
          .catch((error) => console.log("next error:", error));

        // Últimas tocadas
        const historyUrl = `https://public.radio.co/stations/${radio.streaming_id}/history`;
        fetch(historyUrl)
          .then((response) => response.json())
          .then((result) => {
            const prevTracks = (result?.tracks || []).map((item) => ({
              title: item?.title || "",
              artwork: item?.artwork_url || "",
              time: item?.start_time || "",
            }));

            // Faixa atual
            const currentTrack = prevTracks.shift();
            if (!window.isOfflinePlaying) {
              setCurrentTrack(currentTrack.title, currentTrack.artwork, false);
            }

            window?.setPrevTracks(prevTracks);
          })
          .catch((error) => console.log("history error:", error));
      }
    },
    20000,
    true,
    true
  );

  function loadMetadata(title, artwork) {
    if (!window.isOfflinePlaying) {
      setCurrentTrack(title, artwork, false);
    }
    window.refreshTrackInfo();
  }
}
