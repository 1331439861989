import { apiService, consoleService as c } from "./services";
import { getAudioElement } from "./utils/getAudioElement";
import { isSafari } from "./utils/isSafari";

window.isOnline = true;
window.isOfflinePlaying = false;

// atualiza a cor da bolinha
export function updateOfflineModeIndicator(color, text) {
  setTimeout(() => {
    const defaultIndicator = {
      loaded: false,
      color: "red",
      text: "Instalando<br>Modo Offline",
    };
    window.OfflineModeIndicator =
      window.OfflineModeIndicator || defaultIndicator;

    if (window.OfflineModeIndicator.color === "green") {
      return;
    }
    window.OfflineModeIndicator.color = color;
    window.OfflineModeIndicator.text = text;
    document.dispatchEvent(new CustomEvent("updateOfflineModeIndicator"));
  }, 1500);
}

console.log("isSafari=", isSafari);

function onOnlineAfterOfflineAudioEnded(silentMode) {
  document.body.classList.add("online");
  const playing = window.isPlaying();
  if (!playing) {
    c.log(silentMode, "[OFFLINE MODE] Ficou online mas está pausado.");
    return;
  }
  c.log(silentMode, "[OFFLINE MODE] Ficou online, atualizando a página...");
  setTimeout(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (window.isMobile && !window.isPwa) {
      urlParams.delete("autoplay");
    } else {
      urlParams.set("autoplay", "1");
    }
    const newUrl =
      document.location.protocol +
      "//" +
      document.location.host +
      document.location.pathname +
      "?" +
      urlParams.toString();
    window.history.replaceState({}, "_", newUrl);
    document.location.reload();
  }, 0);
}

let alerted = false;
function onOffline(silentMode) {
  if (window.isOnline) {
    window.isOnline = false;
    c.log(silentMode, 'window.isOnline = false');
  }
  if (window.isOfflinePlaying) {
    c.log(silentMode, 'conexão ficou offline novamente, nada a ser feito');
    return;
  }

  document.body.classList.remove("online");
  window.setCurrentTrack?.("Offline Mode", undefined, true);
  window.setNextTrack?.({ title: "", artwork: "" });
  window.setOfflineModeStatus?.("Você está no<br>Modo Offline");

  const audio = getAudioElement();
  let audioSource = audio.querySelector("source");
  if (audio && !audioSource) {
    c.log(silentMode, "[OFFLINE MODE] Elemento <source/> criado automaticamente.");
    audioSource = document.createElement("source");
    audio.appendChild(audioSource);
  }
  if (!audio || !audioSource) {
    c.log(silentMode, "[OFFLINE MODE] Elementos <audio/> e <source/> não foram encontrados.");
    return;
  }
  // const playing = window.isPlaying();
  // if (!playing) {
  //   c.log(silentMode, "[OFFLINE MODE] Ficou offline mas está pausado.");
  //   window.onAutoPlayError?.("[OFFLINE MODE] Ficou offline mas está pausado.");
  //   return;
  // }
  const offlineAudios = apiService.getOfflineAudios();
  if (offlineAudios.length <= 0) {
    c.log(silentMode, "[OFFLINE MODE] Ficou offline mas não tem áudios em cache.");
    return;
  }
  c.log(silentMode, "[OFFLINE MODE] Ficou offline, iniciando áudio em cache...");

  // pausa o áudio principal e os áudios agendados
  document.querySelectorAll("audio").forEach((el) => el.pause());

  // toca o áudio offline
  let offlineIndex = -1;
  window.playNextOfflineAudio = function playNextOfflineAudio() {
    c.log(silentMode, "playNextOfflineAudio()");
    offlineIndex++;
    if (!offlineAudios[offlineIndex]) {
      offlineIndex = 0;
    }

    const fallbackIndex = offlineIndex;
    while (window.localStorage.getItem(offlineAudios[offlineIndex]) === 'played') {
      c.log(silentMode, `[OFFLINE MODE] Música offline ${offlineAudios[offlineIndex]} já foi tocada, pulando...`);
      offlineIndex++;
      if (!offlineAudios[offlineIndex]) {
        offlineIndex = fallbackIndex;
        c.log(silentMode, `[OFFLINE MODE] Música offline ${offlineAudios[offlineIndex]} já foi tocada, mas tocando ela mesmo assim.`);
        break;
      }
    }

    c.log(silentMode, `[OFFLINE MODE] Tocando índice ${offlineIndex}:`, offlineAudios[offlineIndex]);

    const split = decodeURI(offlineAudios[offlineIndex]).split("/");
    const filename = split[split.length - 1];
    const currentTrack = filename.split(".").slice(0, -1).join(".");
    window.setCurrentTrack(currentTrack, undefined, true);

    if (isSafari) {
      // safari
      audio.src = offlineAudios[offlineIndex];
    } else {
      // other browsers
      audio.src = offlineAudios[offlineIndex];
      audioSource.src = offlineAudios[offlineIndex];
    }

    // function addListener(node, eventName) {
    //   function onEvent(eventParam) {
    //     console.log("[playNextOfflineAudio]", eventName, eventParam);
    //   }
    //   node.removeEventListener(eventName, onEvent);
    //   node.addEventListener(eventName, onEvent);
    // }

    // addListener(audio, "error");
    // addListener(audio, "loadstart");
    // addListener(audio, "abort");
    // addListener(audio, "canplay");
    // addListener(audio, "playing");
    // addListener(audio, "emptied");
    // addListener(audio, "ended");
    // addListener(audio, "canplaythrough");
    // addListener(audio, "loadedmetadata");
    // addListener(audio, "loadeddata");
    // addListener(audio, "stalled");
    // addListener(audio, "suspend");
    // addListener(audio, "waiting");

    audio.crossOrigin = 'anonymous';
    audio.load();

    const playResult = audio.play();
    if (playResult instanceof Promise) {
      playResult.then(
        () => { },
        (e) => {
          console.error('Erro ao tocar áudio offline:', e);
          if (!alerted) {
            alerted = true;
            // alert('Erro ao tocar áudio offline');
          }
        });
    }

    document.querySelector("#bg_blur").style.backgroundImage = "url('/play-audio.png')";
  };

  window.playNextOfflineAudio();

  function onOfflinePlaying() {
    window.isOfflinePlaying = true;
    window.localStorage.setItem(offlineAudios[offlineIndex], 'played');
    c.log(silentMode, 'window.isOfflinePlaying = true');
  }
  audio.removeEventListener("playing", onOfflinePlaying);
  audio.addEventListener("playing", onOfflinePlaying);

  function onOfflineEnded() {
    window.isOfflinePlaying = false;
    c.log(silentMode, 'window.isOfflinePlaying = false');
    if (window.isOnline) {
      // áudio offline terminou, mas já está online
      onOnlineAfterOfflineAudioEnded(silentMode);
    } else {
      // áudio offline terminou, mas permanece offline
      window.playNextOfflineAudio();
    }
  }
  audio.removeEventListener("ended", onOfflineEnded);
  audio.addEventListener("ended", onOfflineEnded);
}

function onOnline(silentMode) {
  window.isOnline = true;
  c.log(silentMode, 'window.isOnline = true');

  // substitui as músicas armazenadas em cache que já foram tocadas
  const replace = [];
  for (var i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    const value = window.localStorage.getItem(key);
    if (value === 'played') {
      const audio = getAudioElement();
      const currentSrc = audio.currentSrc;
      if (key === currentSrc) {
        c.log(silentMode, `[OFFLINE MODE] Ficou online, mantendo a música ${key} do modo offline porque é a música sendo tocada.`);
      } else {
        replace.push(key);
      }
    }
  }
  if (replace.length > 0) {
    // c.log(silentMode, '[OFFLINE MODE] Ficou online, substituindo a(s) seguinte(s) música(s) do modo offline:', replace);
    window.fetchOfflineMode?.();
  }
}

/**
 * ```js
 * document.dispatchEvent(new CustomEvent("statusChanged", { detail: "offline" }));
 * ```
 */
function onStatusChanged(e) {
  const currentStatus = window.isOnline ? "online" : "offline";
  const newStatus = e.detail; // "online" | "offline"
  const silentMode = currentStatus === newStatus;

  if (newStatus === "offline") {
    c.log(silentMode, "statusChanged to offline");
    onOffline(silentMode);
  } else {
    c.log(silentMode, "statusChanged to online");
    onOnline(silentMode);
  }
}

export function enableOfflineModeListeners() {
  disableOfflineModeListeners();
  document.addEventListener("statusChanged", onStatusChanged, false);

  // comentar as linhas abaixo para trocar de Wi-Fi para 5G em Android
  // window.addEventListener("offline", onOffline);
  // window.addEventListener("online", onOnline);
}

export function disableOfflineModeListeners() {
  document.removeEventListener("statusChanged", onStatusChanged);

  // comentar as linhas abaixo para trocar de Wi-Fi para 5G em Android
  // window.removeEventListener("offline", onOffline);
  // window.removeEventListener("online", onOnline);
}

export function afterRegister() {
  const idRadio = window.location.pathname.split("/")[1] ?? "";
  const broadcast = new BroadcastChannel(idRadio);
  broadcast.onmessage = (event) => {
    if (event.data) {
      // eslint-disable-next-line default-case
      switch (event.data.type) {
        case 'CACHED':
          apiService.addOfflineAudio(event.data.payload);
          break;
        case 'ALL_CACHED':
          // deleta todas as músicas já tocadas
          for (var i = 0; i < window.localStorage.length; i++) {
            const key = window.localStorage.key(i);
            if (key) {
              const value = window.localStorage.getItem(key);
              if (value === 'played') {
                broadcast.postMessage({ type: "REMOVE_FROM_CACHE", payload: key });
              }
            }
          }
          break;
      }
    }
  };

  broadcast.postMessage({ type: "REQUEST_CACHED" });
  updateOfflineModeIndicator("yellow", "Preparando<br>Modo Offline");

  enableOfflineModeListeners();
}
