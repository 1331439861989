import Echo from "laravel-echo";
import swal from "sweetalert";
import { env } from "../../../env";

export function loadSocket(radio) {
  window.io = require("socket.io-client");
  console.log("Laravel Echo Server host:", env.sockets.host);
  const echo = new Echo({
    broadcaster: "socket.io",
    host: env.sockets.host,
  });

  echo
    .channel(`radiomessage_${radio.folder}`)
    .listen("SendMessage", (event) => {
      const swalParams = event.message.file
        ? { icon: event.message.file, className: "notif" }
        : { icon: "success", title: "Nova Mensagem!" };
      swal({
        ...swalParams,
        content: window.jQuery(
          "<div style='display:contents'>" + event.message.message + "</div>"
        )[0],
      });
    });
}
