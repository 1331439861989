import { useLoginState } from "../../../hooks/useLoginState";
import { useRedirectAfterLogin } from "../../../hooks/useRedirectAfterLogin";

import "./style.scss";

export function DefaultLogin() {
  const {
    idRadio,
    setIdRadio,
    login,
    setLogin,
    senha,
    setSenha,
    loading,
    error,
    fetchRadio,
    loggedIn,
  } = useLoginState();

  useRedirectAfterLogin(idRadio, loggedIn, login);

  return (
    <div className="admin">
      <div className="container">
        <div id="login">
          <img alt="" src="/images/logo.png" width={200} />
          <form
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              fetchRadio();
            }}
          >
            <input type="hidden" name="cmd" value="loga" />
            <input
              type="text"
              name="idRadio"
              placeholder="Nome da rádio"
              value={idRadio}
              onChange={(event) => setIdRadio(event.target.value)}
            />
            <br />
            <input
              type="text"
              name="cnpj"
              placeholder="CNPJ"
              value={login}
              onChange={(event) => setLogin(event.target.value)}
            />
            <br />
            <input
              type="password"
              name="senha"
              placeholder="Senha"
              value={senha}
              onChange={(event) => setSenha(event.target.value)}
            />
            <br />
            {loading ? (
              <div className="loading1">Entrando...</div>
            ) : (
              <input type="submit" name="submit" value="Logar" />
            )}
            <div className="error">{error}</div>
          </form>
        </div>
      </div>
    </div>
  );
}
