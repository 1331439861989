import { useEffect } from "react";
import { TLoginState, useLoginState } from "../../../hooks/useLoginState";
import { useRedirectAfterLogin } from "../../../hooks/useRedirectAfterLogin";

import logo_radio_vooz_fundo_laranja from "./images/logo_radio_vooz_fundo_laranja.png";
import image_headphone from "./images/image_headphone.png";
import icon_programacao_atualizada from "./images/icon_programacao_atualizada.png";
import icon_vinhetas_e_spots from "./images/icon_vinhetas_e_spots.png";
import icon_dicas_de_saude from "./images/icon_dicas_de_saude.png";
import icon_sem_propagandas from "./images/icon_sem_propagandas.png";
import image_hand from "./images/image_hand.png";
import logo_farmarcas_fundo_branco from "./images/logo_farmarcas_fundo_branco.png";

import "./style.scss";

export function FarmarcasLogin() {
  const {
    login,
    setLogin,
    senha,
    setSenha,
    loading,
    error,
    idRadio,
    fetchRadio,
    loggedIn,
  } = useLoginState();

  // toda tela de login de algum grupo deve ter o effect abaixo:
  useEffect(() => {
    const newState: Partial<TLoginState> = {
      grupo: {
        nome: "Farmarcas",
        folder: "farmarcas",
      },
    };
    if (window.location.pathname === "/farmarcas") {
      newState.idRadio = "";
    }
    useLoginState.setState(newState);
  }, []);

  useRedirectAfterLogin(idRadio, loggedIn, login);

  return (
    <div className="login_farmarcas">
      <header>
        <img
          src={logo_radio_vooz_fundo_laranja}
          alt="VOOZ - Rádio Farmarcas"
          title="VOOZ - Rádio Farmarcas"
        />
      </header>
      <div className="container">
        <div id="login" className="text-center">
          <h2>
            Proporcione um ambiente ainda mais agradável para seus clientes e
            colaboradores
          </h2>
          <form
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              fetchRadio();
            }}
          >
            <input type="hidden" name="cmd" value="loga" />
            <input
              type="text"
              name="cnpj"
              placeholder="CNPJ"
              value={login}
              onChange={(event) => setLogin(event.target.value)}
            />
            <br />
            <input
              type="password"
              name="senha"
              placeholder="Senha"
              value={senha}
              onChange={(event) => setSenha(event.target.value)}
            />
            <br />
            {loading ? (
              <div className="loading1">Entrando...</div>
            ) : (
              <input type="submit" name="submit" value="Acessar" />
            )}
            <div className="error">{error}</div>
          </form>
          <div className="row descricao_farmarcas">
            <div className="col-sm-6 col-xs-12">
              <p>
                A Farmarcas oferece o serviço de Rádio Indoor exclusivo para
                seus associados: <span className="orange">a Rádio Vooz.</span>
              </p>
              <p>
                Além de uma playlist com artistas que estão nas paradas musicais
                de maior sucesso nas rádios do Brasil e do mundo, a Rádio Vooz
                oferece conteúdo com informações sobre o mundo da saúde,
                bem-estar físico e mental. E ainda, inserções com as ações
                efetuadas por sua loja.
              </p>
            </div>
            <div className="col-sm-6 col-xs-12">
              <img alt="" src={image_headphone} />
            </div>
          </div>
          <div className="row text-center" id="baixo_farmarcas">
            <div className="col-xs-6 col-md-3">
              <img alt="" src={icon_programacao_atualizada} />
              <p>Programação atualizada com músicas e estilos variados</p>
            </div>
            <div className="col-xs-6 col-md-3">
              <img alt="" src={icon_vinhetas_e_spots} />
              <p>Vinhetas e spots personalizados com a marca da rede</p>
            </div>
            <div className="col-xs-6 col-md-3">
              <img alt="" src={icon_dicas_de_saude} />
              <p>Dicas de saúde, beleza e bem-estar para todos os públicos</p>
            </div>
            <div className="col-xs-6 col-md-3">
              <img alt="" src={icon_sem_propagandas} />
              <p>Sem propagandas da concorrência (como nas rádios comuns)</p>
            </div>
          </div>
          <div className="row text-left" id="pedidos">
            <div className="col-sm-6 col-xs-12">
              <img alt="" src={image_hand} />
            </div>
            <div className="col-sm-6 col-xs-12">
              <h2 className="orange">Pedido musical</h2>
              <p>
                Você pode personalizar ainda mais a playlist da sua farmácia,
                basta acessar ao sistema online da rádio e pedir sua música
                favorita, ou mesmo, as músicas que possuem mais apelo com o seu
                público.
              </p>
              <p>
                É importante saber que o seu pedido ficará numa fila de espera e
                será tocado na ordem de pedidos do dia!
              </p>
            </div>
          </div>
          <div id="instrucoes">
            <h2 className="orange">
              Saiba como usar a rádio de maneira eficaz
            </h2>
            <ol>
              <li>
                <p>
                  Evite o uso do Wi-Fi, pois pode ter oscilação de sinal,
                  prefira a conexão via cabo.
                </p>
              </li>
              <li>
                <p>
                  Use o navegador Google Chrome, Mozilla Firefox, Safari - nunca
                  use o Internet Explorer, pois já foi descontinuado.
                </p>
              </li>
              <li>
                <p>
                  Importante: limpe seu cache da máquina e os armazenamentos de
                  informações, pelo menos 1 vez por semana.
                </p>
              </li>
              <li>
                <p>
                  Se utiliza seu celular para conectar, lembre-se de tirá-lo do
                  silencioso para a execução da Rádio.
                </p>
              </li>
              <li>
                <p>
                  Caso a Rádio pare de funcionar, verifique a sua internet.
                  Verifique se outras páginas não abrem ou ainda, faça o teste
                  em seu celular utilizando os Dados Móveis, caso esteja
                  funcionando em seu aparelho celular, reinicie a sua internet.
                </p>
              </li>
            </ol>
          </div>
          <h2 className="orange aproveite">Faça o seu login e aproveite!</h2>
          <h4 className="contato">
            Entre em contato:{" "}
            <a
              href="mailto:comercial@fabriccamultimidia.com.br"
              target="_blank"
              rel="noreferrer"
            >
              comercial@fabriccamultimidia.com.br
            </a>
          </h4>
        </div>
      </div>
      <div id="rodape_farmarcas">
        <div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <p>
              A <strong>Rádio Vooz</strong> é uma plataforma exclusiva da
              Farmarcas com tecnologia da{" "}
              <a
                href="https://www.fabriccamultimidia.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                Fabricca Multimídia
              </a>
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <img
              src={logo_farmarcas_fundo_branco}
              alt="Farmarcas"
              title="Farmarcas"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
