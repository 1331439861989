import { LoadingProps } from "./types";
import "./style.scss";

export function Loading({ message }: LoadingProps) {
  return (
    <div className="loading2">
      <span className="loader"></span>
      <small>{message ?? "Carregando..."}</small>
    </div>
  );
}
