import { Loading } from "./Loading";
import { SpeedControl } from "./SpeedControl";
import "./style.scss";

export function RadioIndoor() {
  // pesquisar por '$("#tts_modal form").submit' no projeto
  return (
    <form className="tts-form">
      <fieldset>
        <textarea
          name="text"
          className="form-control"
          maxLength={200}
          placeholder="Digite o texto aqui"
        />
        <select className="form-control" name="voice">
          <option value="nf">Voz Feminina</option>
          <option value="nm">Voz Masculina</option>
        </select>
        <SpeedControl />
        <Loading />
        <label className="beep">
          <input type="checkbox" name="beep" /> Tocar beep antes da fala.
        </label>
        <input type="submit" value="Falar" className="btn btn-primary" />
      </fieldset>
    </form>
  );
}
