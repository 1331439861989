import debounce from "just-debounce";
import { getAudioElement } from "./getAudioElement";
import { getVolume } from "./getVolume";
import { isSafari } from "./isSafari";

export function playAnotherAudio(
  anotherAudio: HTMLAudioElement,
  {
    src = "",
    isHls = false,
    onPlay = () => {},
    onPaused = () => {},
    onEnded = () => {},
    onEndedOrPause = () => {},
    playbackRate = 1.0,
    playMainAudioOnEnd = true,
  }
) {
  const $ = window.jQuery;
  const audio = getAudioElement(isHls);

  function pauseOtherAudios(ref: HTMLAudioElement) {
    Array.from(document.querySelectorAll("audio"))
      .filter((el) => el !== ref && el !== audio)
      .forEach((el) => el.pause());
  }

  let paused = false;
  function fadeOutMainAudio(callPlay: boolean) {
    paused = false;
    console.log("[playAnotherAudio] fadeOutMainAudio");
    if (isSafari) {
      audio.pause();
      if (callPlay) {
        if (src) {
          $(anotherAudio).attr("src", src);
        }
        pauseOtherAudios(anotherAudio);
        anotherAudio.play();
      }
    } else {
      if (audio.volume === 0.015) {
        console.log("volume já reduzido.", audio.volume);
        if (callPlay && src) {
          $(anotherAudio).attr("src", src); // 1
          pauseOtherAudios(anotherAudio); // 2
          anotherAudio.play(); // 3
        }
      } else {
        console.log("reduzindo volume...", audio.volume);
        $(audio).animate({ volume: 0.015 }, 1000, () => {
          console.log("volume reduzido.", audio.volume);
          if (callPlay) {
            pauseOtherAudios(anotherAudio); // 2
            anotherAudio.play(); // 3
          }
        });
        if (callPlay && src) {
          $(anotherAudio).attr("src", src); // 1
        }
      }
    }
  }
  const debouncedFadeOutMainAudio = debounce(fadeOutMainAudio, 100);

  function playMainAudio(fadeInTime = 1000) {
    setTimeout(() => {
      console.log("[playAnotherAudio] playMainAudio");
      if (isSafari) {
        pauseOtherAudios(audio);
        audio.play();
      } else {
        const volume = getVolume() / 100;
        $(audio).animate({ volume }, fadeInTime);
      }
    });
  }
  const debouncedPlayMainAudio = debounce(playMainAudio, 100);

  $(anotherAudio)
    .off("ended.anotherAudio")
    .on("ended.anotherAudio", () => {
      onEndedOrPause();
      onEnded();
      if (playMainAudioOnEnd) {
        debouncedPlayMainAudio();
      }
    });

  $(anotherAudio)
    .off("pause.anotherAudio")
    .on("pause.anotherAudio", () => {
      paused = true;
      onEndedOrPause();
      onPaused();
      if (playMainAudioOnEnd) {
        debouncedPlayMainAudio(1);
      }
    });

  $(anotherAudio)
    .off("play.anotherAudio")
    .on("play.anotherAudio", () => {
      onPlay();
      if (paused) {
        debouncedFadeOutMainAudio(false);
      }
    });

  $(anotherAudio)
    .off("playing.anotherAudio")
    .on("playing.anotherAudio", () => {
      const volume = getVolume() / 100;
      anotherAudio.volume = volume;

      console.log("setting playbackRate=", playbackRate);
      anotherAudio.playbackRate = playbackRate;
    });

  debouncedFadeOutMainAudio(true);
}
