import { useEffect, useMemo, useRef, useState } from "react";
import { getAudioElement } from "../../utils/getAudioElement";
import { playAnotherAudio } from "../../utils/playAnotherAudio";
import { AudioProps } from "./types";
import Cron from "croner";
import cronstrue from "cronstrue/i18n";
import { format } from "date-fns";

import "./style.scss";

declare global {
  interface Window {
    jQuery: any;
  }
}
const $ = window.jQuery;

export function Audio({ name, url, cron, isHls }: AudioProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [proximaExecucao, setProximaExecucao] = useState<Date | null>();
  const cronString = useMemo<string>(
    () =>
      cron
        ? cronstrue.toString(cron, {
            use24HourTimeFormat: true,
            locale: "pt_BR",
          })
        : "",
    [cron]
  );

  useEffect(() => {
    if (!cron) {
      return;
    }

    $(audioRef.current).addClass("disabled");

    // caso o áudio tenha sido agendado, toca-o no momento desejado
    console.log(`agendando áudio ${name}`);
    const job = Cron(cron, { name }, () => {
      setProximaExecucao(job.nextRun());

      const radioRef = getAudioElement(isHls);
      if (!radioRef) {
        return;
      }

      if (radioRef.paused) {
        console.log("áudio agendado ignorado porque o player está pausado");
        return;
      }

      if (!$("#audios_modal").data("bs.modal")?.isShown) {
        $("#audios_modal").addClass("visible-while-playing").modal("show");
      }

      console.log("iniciando áudio agendado; fading out...");
      if (audioRef.current) {
        playAnotherAudio(audioRef.current, {
          isHls,
          onPlay: () => {
            $(".audio-component").removeClass("enabled").addClass("disabled");
          }
        })
      }
    });

    setProximaExecucao(job.nextRun());

    (window as any).spots = (window as any).spots ?? [];
    const i = (window as any).spots.length;
    (window as any).spots[i] = job;
    console.log(
      `áudio ${name} agendado para ${job.nextRun()}`,
      `digite \`spots[${i}].trigger();\` para tocá-lo imediatamente (modo teste)`
    );

    return () => {
      job.stop();
      console.log(`agendamento do áudio ${name} cancelado`);
    };
  }, [name, cron, isHls, setProximaExecucao]);

  return (
    <div
      className="audio-component"
      ref={(ref) => {
        if (ref) {
          containerRef.current = ref;
        }
      }}
    >
      <h2>{name}</h2>
      {cron && proximaExecucao ? (
        <>
          <p>{`Agendamento: ${cronString}`}</p>
          <p>{`Próxima execução: ${format(proximaExecucao, "HH:mm")}`}</p>
        </>
      ) : (
        <></>
      )}
      <audio
        controls
        controlsList="nodownload"
        ref={(ref) => {
          if (ref) {
            audioRef.current = ref;
            audioRef.current.onplay = function () {
              containerRef.current?.classList.add("enabled");
              $(audioRef.current)
                .closest(".modal-body")
                .find(".audio-component:not(.enabled)")
                .addClass("disabled");
              if (audioRef.current) {
                playAnotherAudio(audioRef.current, {
                  isHls,
                  onEnded: () => {
                    if (cron && $("#audios_modal").hasClass("visible-while-playing")) {
                      $("#audios_modal").modal("hide");
                    }
                  },
                  onEndedOrPause: () => {
                    $(".audio-component").removeClass("enabled").removeClass("disabled");
                  }
                });
              }
            };
          }
        }}
      >
        <source src={url} />
      </audio>
    </div>
  );
}
