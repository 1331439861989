import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiService, TError, TRadio } from "../services";

function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

function getTimestampInSeconds() {
  return Math.floor(Date.now() / 1000)
}

export function useRadio() {
  const { idRadio } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<TError>();
  const [radio, setRadio] = useState<TRadio>();
  const [refreshRadio, setRefreshRadio] = useState<number>(0);

  // chama a API
  const jsonAudios = useRef<string>();
  useEffect(() => {
    if (!idRadio) {
      return;
    }
    const cacheBuster = refreshRadio === 0 ? 0 : getTimestampInSeconds();
    apiService.getRadio(idRadio, cacheBuster).then((radioOrError) => {
      if (apiService.isError(radioOrError)) {
        if (refreshRadio === 0) {
          // primeira tentativa
          // carregamento inicial
          setError(radioOrError as TError);
        } else {
          // próximas tentativas
          // atualização automática dos áudios agendados em segundo plano
          // ignora o erro
        }
      } else {
        const radio = radioOrError as TRadio;
        radio.url = apiService.getRadioUrl(radio);
        const _jsonAudios = JSON.stringify(radio.audios);
        if (jsonAudios.current !== _jsonAudios) {
          jsonAudios.current = _jsonAudios;
          setRadio(radio);
        } else {
          console.log("os áudios da rádio não sofreram alterações");
        }
      }
      setLoading(false);
    });
  }, [idRadio, navigate, refreshRadio]);

  // atualiza automaticamente os áudios agendados em segundo plano a cada X minutos
  useEffect(() => {
    const minMinute = 5;
    const maxMinute = 10;
    const intervalMs = getRandomNumber(
      minMinute * 60 * 1000,
      maxMinute * 60 * 1000
    );
    const intervalMin = (intervalMs / 60000).toFixed(2);
    console.log(
      `checkRadio() a cada ${intervalMin} minutos. digite \`window.checkRadio()\` para chamar imediatamente.`
    );
    const checkRadio = () => {
      console.log("checkRadio() called!");
      setRefreshRadio((prevState) => prevState + 1);
    };
    (window as any).checkRadio = checkRadio;
    const interval = setInterval(checkRadio, intervalMs);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // trata os erros
  useEffect(() => {
    const grupo = radio?.grupo;
    if (error?.httpCode === 401 && grupo?.folder) {
      console.log("[useRadio] 401");
      navigate(`/${grupo.folder}`, {
        replace: true,
        state: { idRadio, grupo },
      });
    } else if (error?.httpCode === 404) {
      // data_not_found
      console.log("[useRadio] 404");
      navigate("/", { replace: true });
    }
  }, [error?.httpCode, idRadio, navigate, radio?.grupo]);

  return {
    loading,
    error,
    radio,
  };
}
