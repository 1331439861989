import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { Player } from "../Player";
import { useEffect } from "react";
import { useLoginState } from "../../hooks/useLoginState";
import { loginIndex } from "../Login";
import { Loading } from "../Loading";
import { TError, TGrupo, apiService } from "../../services";

export function LoginOrPlayer() {
  const [searchParams] = useSearchParams();
  const autoplay = searchParams.get("autoplay") === "1" ? "1" : "0";

  const navigate = useNavigate();

  const params = useParams();
  const idRadioFromRoute = params?.idRadio ?? "";
  const loginFromRoute = params?.cnpj ?? "";
  const senhaFromRoute = params?.senha ?? "";

  const {
    grupo,
    idRadio,
    setIdRadio,
    loggedIn,
    login,
    setLogin,
    senha,
    setSenha,
    fetchRadio,
    logout,
  } = useLoginState();

  // Efeito 1
  // Faz logout caso o acesso seja feito pela rota raiz (/)
  useEffect(() => {
    if (loggedIn && !idRadioFromRoute) {
      logout(false);
    } else {
      console.log("[LoginOrPlayer] Efeito 1 não precisou ser executado");
    }
  }, [loggedIn, idRadioFromRoute, logout]);

  // Efeito 2
  // Preenche automaticamente o nome da rádio, o CNPJ e a senha a partir da rota acessada
  // e tenta realizar login
  useEffect(() => {
    let executou = Boolean(
      idRadioFromRoute || loginFromRoute || senhaFromRoute
    );
    if (idRadioFromRoute && idRadio !== idRadioFromRoute) {
      executou = true;
      console.log("[LoginOrPlayer] Rádio:", idRadioFromRoute);
      setIdRadio(idRadioFromRoute);
    }
    if (loginFromRoute && login !== loginFromRoute) {
      executou = true;
      console.log("[LoginOrPlayer] CNPJ:", loginFromRoute);
      setLogin(loginFromRoute);
    }
    if (senhaFromRoute && senha !== senhaFromRoute) {
      executou = true;
      console.log("[LoginOrPlayer] Senha obtida pela rota");
      setSenha(senhaFromRoute);

      // Remove a senha da rota e do histórico
      navigate(`/${idRadioFromRoute}/${loginFromRoute}?autoplay=${autoplay}`, {
        replace: true,
      });
    }
    if (executou) {
      // Determina se a rádio requer (ou não) login
      fetchRadio();
    } else {
      console.log("[LoginOrPlayer] Efeito 2 não precisou ser executado");
    }
    // NÃO COLOCAR o idRadio e login nas dependências
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoplay,
    idRadioFromRoute,
    setIdRadio,
    loginFromRoute,
    setLogin,
    senhaFromRoute,
    setSenha,
    fetchRadio,
  ]);

  // Efeito 3
  // Descobre o grupo da rádio para exibir a respectiva página de login
  useEffect(() => {
    // o timeout garante que esse efeito seja executado após o Efeito 2
    setTimeout(() => {
      const _idRadio = idRadioFromRoute || idRadio || "";
      if (!_idRadio) {
        console.log("[LoginOrPlayer] Efeito 3 não precisou ser executado");
        return;
      }
      apiService.getGrupoRadio(_idRadio).then((grupoOrError) => {
        let grupo: TGrupo;
        if (apiService.isError(grupoOrError)) {
          const e = grupoOrError as TError;
          // prettier-ignore
          console.log("[LoginOrPlayer] Falha ao determinar o grupo da rádio:", e);

          // fallback
          grupo = { nome: "", folder: "" };
        } else {
          grupo = grupoOrError as TGrupo;
        }
        console.log("[LoginOrPlayer] Grupo da rádio:", grupo);
        useLoginState.setState({ grupo });
      });
    });
    // NÃO COLOCAR o idRadio nas dependências
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRadioFromRoute]);

  if (loggedIn) {
    return <Player />;
  }

  if (idRadioFromRoute && grupo === null) {
    return <Loading message="Localizando grupo da rádio..." />;
  }

  const grupoFolder = grupo?.folder ?? "";
  return loginIndex[grupoFolder] ?? loginIndex[""];
}
