import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export function useRedirectAfterLogin(
  idRadio: string,
  loggedIn: boolean,
  login: string
): void {
  const [searchParams] = useSearchParams();
  const autoplay = searchParams.get("autoplay") === "1" ? "1" : "0";

  const navigate = useNavigate();

  useEffect(() => {
    if (idRadio && loggedIn) {
      const loginString = String(login || "");
      if (loginString) {
        console.log("[useRedirectAfterLogin] navigate (1)");
        navigate(
          `/${idRadio}/${loginString.replace(/\D+/g, "")}?autoplay=${autoplay}`,
          {
            replace: true,
            state: { idRadio, login },
          }
        );
      } else {
        console.log("[useRedirectAfterLogin] navigate (2)");
        navigate(`/${idRadio}`, { replace: true, state: { idRadio } });
      }
    } else {
      // console.log("[useRedirectAfterLogin] don't navigate");
    }
  }, [idRadio, login, loggedIn, autoplay, navigate]);
}
