import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import { env } from "../env";
import { TRadio, TError, TGrupo, TMetadata } from "./types";
import { updateOfflineModeIndicator } from "../offline_mode";
import { storageService } from "./StorageService";

export class ApiService {
  private user: string | null;
  private password: string | null;
  private instance: AxiosInstance;
  private offlineAudios: Set<string>;

  constructor() {
    this.user = null;
    this.password = null;
    this.instance = axios.create({
      baseURL: env.api.host,
    });
    this.offlineAudios = new Set();
  }

  public addOfflineAudio(url: string): void {
    if (!this.offlineAudios.has(url)) {
      this.offlineAudios.add(url);
      console.log(`[ApiService] áudio ${url} disponível no modo offline`);
      updateOfflineModeIndicator("green", "Modo Offline<br>Disponível");
    }
  }

  public getOfflineAudios(): string[] {
    return Array.from(this.offlineAudios);
  }

  private _initOfflineMode(idRadio: string, links: string[]): void {
    const payload = links.map((link) => encodeURI(`${env.api.host}/api/play-audio/${idRadio}/${link}`))
      .filter((url) => window.localStorage.getItem(url) !== 'played')
      .slice(0, 10); // limit

    const cacheName = idRadio;
    const broadcast = new BroadcastChannel(cacheName);
    broadcast.postMessage({ type: "ADD_TO_CACHE", payload });
  }

  public initOfflineMode(idRadio: string, config: AxiosRequestConfig): void {
    const newIdRadio = window.location.pathname.split("/")?.[1];
    const _idRadio = newIdRadio || idRadio || "";

    const yearMonthDay = new Date().toISOString().split("T")[0];
    const cacheKey = `${yearMonthDay}_${_idRadio}_audio-links`;
    const cachedLinks = storageService.getItem(cacheKey);

    if (cachedLinks) {
      // console.log('[ApiService] initOfflineMode cachedLinks=', cachedLinks);
      this._initOfflineMode(_idRadio, cachedLinks);
    } else {
      this.instance
      .get<{ links: string[] }>(`/api/audio-links/${_idRadio}`, config)
      .then(({ data }) => {
        if (data.links.length === 0) {
          return;
        }
        storageService.setItem(cacheKey, JSON.stringify(data.links));
        // console.log('[ApiService] initOfflineMode data.links=', data.links);
        this._initOfflineMode(_idRadio, data.links);
      })
      .catch((error) =>
        console.log("[ApiService] falha ao obter os links para o modo offline:", error)
      );
    }
  }

  public isError(response: TError | TGrupo | TRadio): boolean {
    return typeof (response as TError).error !== "undefined";
  }

  public setUser(user: string): void {
    this.user = user;
  }

  public setPassword(password: string): void {
    this.password = password;
  }

  private returnError(error: unknown, debugStr?: string): TError {
    console.warn('[ApiService] returnError:', error);
    const typedError = error as AxiosError<Omit<TError, "httpCode">>;
    const httpCode = typedError.response?.status ?? 0;
    if (axios.isAxiosError(error) && typedError?.response) {
      return { httpCode, ...typedError.response.data };
    }
    let errorMessage = "Falha ao se comunicar com o back-end";
    if (debugStr) {
      errorMessage += ` (${debugStr})`
    }
    return { httpCode, error: errorMessage };
  }

  public async getGrupoRadio(idRadio: string): Promise<TGrupo | TError> {
    try {
      const response = await this.instance.get<TMetadata>(
        `/api/radio/${idRadio}/metadata`
      );
      return response.data?.grupo;
    } catch (error) {
      return this.returnError(error, 'getGrupoRadio');
    }
  }

  public async getRadio(idRadio: string, cacheBuster = 0): Promise<TRadio | TError> {
      let storageKey = `${idRadio}_getRadio`;

      const config: AxiosRequestConfig = {};
      if (this.user && this.password) {
        config.auth = {
          username: this.user,
          password: this.password,
        };

        storageKey += `_${this.user}`;
      }

      let cachedData = storageService.getItem(storageKey);
      try {
        const response = await this.instance.get<TRadio>(
          `/api/radio/${idRadio}?cache_buster=${cacheBuster}`,
          config
        );
        storageService.setItem(storageKey, response.data);
        cachedData = response.data;
        console.log('[ApiService] getRadio() não utilizou cache.');
      } catch (error) {
        if (cachedData) {
          console.log('[ApiService] getRadio() utilizou cache.', error);
        } else {
          return this.returnError(error, 'getRadio');
        }
      }

      // Offline mode
      if (cachedData?.offline_mode) {
        (window as any).fetchOfflineMode = () => {
          this.initOfflineMode(idRadio, config);
        }
        setTimeout((window as any).fetchOfflineMode, 3000);
      } else {
        console.log(`[ApiService] a rádio ${idRadio} não possui modo offline. cadastre as credenciais de FTP pelo admin.`);
      }

      return cachedData;
  }

  public getRadioUrl(radio: TRadio): string {
    if (!radio.streaming_id) {
      return "";
    }
    const date = String(Date.now());

    if (radio.type_server === "hls") {
      const host = encodeURIComponent(env.api.host);
      const url = encodeURIComponent(
        radio.streaming_id.replace("{date}", date)
      );
      return `${env.api.host}/proxy.php?host=${host}&url=${url}`;
    }

    const isURL =
      radio.streaming_id.startsWith("http://") ||
      radio.streaming_id.startsWith("https://");
    return isURL
      ? radio.streaming_id
      : `https://${radio.type_server}.radio.co/${radio.streaming_id}/listen?t=${date}`;
  }
}
