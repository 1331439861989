import { useEffect, useRef } from "react";
import "./style.scss";
import type { BeforeInstallPromptEvent } from "./types";

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

// Initialize deferredPrompt for use later to show browser install prompt.
let deferredPrompt: BeforeInstallPromptEvent | null = null;

function onBeforeInstallPrompt(e: BeforeInstallPromptEvent) {
    e.preventDefault();
    console.log("beforeinstallprompt triggered!");
    deferredPrompt = e;
}
window.addEventListener("beforeinstallprompt", onBeforeInstallPrompt);

export function InstallPwa() {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setTimeout(() => {
            if (deferredPrompt && !(window as any).isPwa) {
                containerRef.current?.classList.add("opened");
            }
        }, 1000);
    }, []);

    return (
        <div className="install-pwa-component" ref={containerRef}>
            <div className="install">
                <button type="button" onClick={() => deferredPrompt?.prompt()}>
                    Instalar
                </button>
            </div>
            <span className="text">
                Instale nosso player como PWA e aproveite recursos avançados!
            </span>
            <div
                className="close"
                onClick={() => containerRef.current?.classList.remove("opened")}
            >
                <div className="fa fa-times"></div>
            </div>
        </div>
    );
}
