type TEnv = {
  /**
   * EncryptStorage Secret Key
   * @see https://github.com/michelonsouza/encrypt-storage
   */
  storageKey: string;
  api: {
    /**
     * Host da API
     * @example http://localhost:8000
     */
    host: string;
  };
  sockets: {
    /**
     * Host do Laravel Echo Server
     * @example http://localhost:6001
     */
    host: string;
  };
};

export const env: TEnv = {
  storageKey: "Y3GxlHKcgEXFe5qnlookZ2mQxgClBoP7",
  api: {
    host: "https://adminmeuplay.fabriccamultimidia.com.br",
  },
  sockets: {
    host: "https://adminmeuplay.fabriccamultimidia.com.br",
  },
};
