import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { env } from "../../env";
import { storageService } from "../../services/StorageService";

import "./style.scss";

export function Container() {
  const [isHomolog, setIsHomolog] = useState(false);

  // utilizado pelo script jquery.radiocoplayer.min.js
  useEffect(() => {
    if (typeof window.jQuery?.getJSON !== 'undefined') {
      window.jQuery.getJSON('/manifest.json')
        .then((data: { id: string }) => {
          setIsHomolog(String(data?.id).startsWith('hom_'));
        }, () => {});
    }

    storageService.setItem("url_api", env.api.host, true);
  }, [setIsHomolog]);

  return (
    <div className="container-component">
      {isHomolog && <div id="hom">[HOMOLOG]</div>}
      <Outlet />
    </div>
  );
}
